.landingBG{
    background-color: #FF6616;
}
.landing {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.landingLogo {
    width: 10%;
}


@keyframes fireAnimation {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.landing h1 {
    font-size: 9vw;
    font-weight: 300;
    margin: 0;
    padding: 0;
    color: transparent;
    background-image: linear-gradient(90deg, #ff0f0f, #ff2802, #ffbb00, #e4c40d);
    background-size: 400% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    animation: fireAnimation 10s ease-in-out infinite;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}

.landingButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width : 100%;
}

.footerLogo {
    width: 50px;
    border-radius: 50%;
    height: 50px;
}

.contentWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 2rem;

}

.contentItem {
    flex: 1;
    min-width: 0; 
    font-size: 2.5rem;

}



.contentItem p {
    flex: 1;
    min-width: 0; 
    font-size: 2.5rem;
    margin-top: -40px;
    
}

.contentItemMap {
    flex: 1;
    min-width: 0; 
    margin-top: 50px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .contentWrapper {
        flex-direction: column;
    }    
    .landingLogo {
        width: 30%; 
    }

    .landing h1 {
        font-size: 13vw; 
        margin-bottom: 20px;
    }
    .contentItemMap
    {
        margin:auto;
        padding-top: 10px;
    }
    .contentItem {
        text-align: center;
        margin-top: -40px;
    }

}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.loading-image {
    width: 300px;  
    height: 300px;  
    filter: drop-shadow(0 0 5px rgba(234, 20, 20, 0.141))
            drop-shadow(0 0 10px rgba(255, 4, 4, 0.098))
            
    
}

.loading-text {
    margin-top: 70px;
    font-size: 30px;
    text-align: center;
    color: #000000;
}


.svg {
    filter: drop-shadow(0px 4px 4px rgba(240, 0, 0, 0.25))
            drop-shadow(0px 4px 40px rgba(240, 0, 0, 0.25))
            drop-shadow(0px 4px 60px rgba(240, 0, 0, 0.25))
            drop-shadow(0px 4px 80px rgba(240, 0, 0, 0.25))
            drop-shadow(0px 4px 120px rgba(240, 0, 0, 0.25))
            drop-shadow(0px 4px 140px rgba(240, 0, 0, 0.25));
    animation: fire 2s infinite steps(10);
}




